import { TitleStyled, useResponsiveBooleansContext } from 'backgammon_ui_shared';
import React from 'react';
import { BetaRibbon, Container, Icon, NewBadge } from './CarouselItem.styled';
import { getStaticResource } from '../../../../../shared';
import { isDesktopScreen } from '../../../../../app.constants';

interface Props {
  title: string;
  iconPath?: string;
  disabled?: boolean;
  onClick?(): void;
  background?: string;
  isBeta?: boolean;
  betaText?: string | number;

  isNew?: boolean;
  isSmallSVG?: boolean;
}

function CarouselItem({
  title,
  iconPath,
  onClick,
  isBeta = false,
  betaText = 'BETA',
  isNew,
  disabled,
  background,
  isSmallSVG,
}: Props) {
  const { isSuperSmall, isTabOrScreen } = useResponsiveBooleansContext();

  return (
    <Container
      isDesktop={isDesktopScreen}
      disabled={disabled}
      onClick={onClick}
      isTabOrScreen={isTabOrScreen}
      isSuperSmall={isSuperSmall}
      background={background}
    >
      {isBeta ? <BetaRibbon>{betaText}</BetaRibbon> : null}
      {isNew ? <NewBadge>NEW</NewBadge> : null}
      {
        <Icon
          isSmallSVG={isSmallSVG}
          isSuperSmall={isSuperSmall}
          isTabOrScreen={isTabOrScreen}
          iconPath={getStaticResource(iconPath)}
        />
      }
      <TitleStyled
        isCentered={true}
        marginTop={isTabOrScreen ? -30 : 0}
        isStretched={false}
        isInUpperCase={false}
        isSubHeading={true}
        color="text"
        style={{ fontSize: '13px', fontWeight: '400' }}
      >
        {title}
      </TitleStyled>
    </Container>
  );
}

export default CarouselItem;
