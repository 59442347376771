import { bgColors } from 'backgammon_ui_shared';
import styled, { CSSObject } from 'styled-components';
import { size } from '../../../../styles/mixins/size';

export const Container = styled.button<{
  disabled?: boolean;
  isTabOrScreen: boolean;
  isSuperSmall?: boolean;
  isDesktop?: boolean;
  background?: string;
}>(
  ({ disabled, background }): CSSObject => ({
    border: 0,
    background: background ?? bgColors.buttonDefault,
    // borderRadius: '13px',
    position: 'relative',
    zIndex: 1,
    color: bgColors.textPrimary,
    display: 'flex',
    flexDirection: 'column', // Ensure vertical layout
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    opacity: disabled ? 0.7 : 1,
    transition: 'filter 0.1s ease-in-out, transform 0.1s linear',
    ...(!disabled && {
      '&:hover': { transform: 'scale(1.05)' },
      '&:active': { filter: 'brightness(0.8)' },
    }),

    // ['@media(min-width: 100px)']: {
    width: '102px',
    height: '130px', // Ensure height is still 130px
    margin: 0,
    padding: 0,
    borderRadius: '5px',

    '& > h1': {
      fontSize: '1.2em',
      margin: 0,
    },
    '&::after': {
      content: 'BETA',
      position: 'fixed',
      width: '35px',
      height: '25px',
      background: bgColors.correct,
      top: '7px',
      left: '-20px',
      textAlign: 'center',
      fontSize: '13px',
      lineHeight: '27px',
      transform: 'rotate(-45deg)',
    },
    // },
  }),
);

export const Icon = styled.span<{
  iconPath: string;
  isSuperSmall: boolean;
  isTabOrScreen: boolean;
  isSmallSVG: boolean;
}>(
  ({ iconPath }): CSSObject => ({
    ...size('83px'), // Ensure height is 130px
    width: '83px',
    height: '86px',
    alignSelf: 'center',
    justifySelf: 'center',
    display: 'block',
    backgroundImage: `url("${iconPath}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 0%;',
    backgroundSize: 'cover',
    borderRaduis: '7px',
    ['@media(min-width: 1200px)']: {},
  }),
);

export const SmallIcon = styled.img<{ isSmall?: boolean }>(({ isSmall }) => ({
  width: isSmall ? '30px' : '35px',
  height: isSmall ? '30px' : '35px',
}));

export const BetaRibbon = styled.div`
  --f: 0px; /* control the folded part */
  --r: 0px; /* control the ribbon shape */
  --t: -5px; /* the top offset */

  position: absolute;
  top: var(--t);
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px var(--f) calc(10px + var(--r));
  background: ${bgColors.blunder};
  border-radius: 50px;
  white-space: nowrap;

  z-index: 10;
`;

export const NewBadge = styled.div`
  --f: 0px; /* control the folded part */
  --r: 0px; /* control the ribbon shape */
  --t: -5px; /* the top offset */

  position: absolute;
  top: var(--t);
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px var(--f) calc(10px + var(--r));
  background: ${bgColors.blunder};
  border-radius: 50px;
  white-space: nowrap;

  z-index: 10;
`;
